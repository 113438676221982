import React, { Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react";

// routes config
import routes from "../../routes/Router";
import { useContext } from "react";
import { AuthContext } from "../../helpers/AuthContext";
import StudentView from "../../views/Studentview/StudentView";
import Login from "../../views/Login";

const AppContent = () => {
  const { authState , setAuthState} = useContext(AuthContext);

  const [user, setuser] = useState(null);

  useEffect(() => {
    setuser(localStorage.getItem("user"));
  }, []);

  return (
    <CContainer fluid>
      <Suspense fallback={<CSpinner color="primary" />}>
        
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Routes>
          {routes.map((route, idx) => {
            // authState.role
            console.log(route.role, user)
            return user === "admin"
              ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.element />}
                  />
                )
              : (user === "student" && route.role=="student") && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.element />}
                  />
                );
          })}
          
          ({user === "admin" && (<Route path="*" element={<Navigate to="dashboard" replace />} />)})
          ({user === "student" && (<Route path="*" element={<Navigate to="studentview" replace />} />)})
           
        </Routes>
        </AuthContext.Provider>
      </Suspense>
    </CContainer>
  );
};

export default React.memo(AppContent);
