import React, { useContext, useRef } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { AuthContext } from "../../helpers/AuthContext";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate} from "react-router-dom";
import useCountdown from "../../helpers/CountDownTimer";
import "./studentview.css";
import { CSpinner } from "@coreui/react";

import Report from "./Reports";

import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CToast,
  CToastHeader,
  CToastBody,
  CToaster
} from "@coreui/react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CFormLabel,
  CFormSelect,
  CFormCheck,
  CFormFeedback,
  CInputFile,
  CRow,
  CCardBody,
  CFormTextarea,
  CCard,
  CCardTitle,
  CContainer,
  CDateRangePicker,
  CProgress,
} from "@coreui/react";

function StudentView() {
  const navigate = useNavigate();
  const [token] = useState(localStorage.getItem("accessToken"));
  const { hoursLeft, minutesLeft, secondsLeft } = useCountdown();
  const { authState } = useContext(AuthContext);
  const [adaats, setadaats] = useState([]); //used for weekly
  const [adaatsD, setadaatsD] = useState([]); //used for daily
  const [adaatsM, setadaatsM] = useState([]); // used for monthly
  const [displayAdaats, setDisplayAdaats] = useState([]); //final display
  const [displayMiqaats, setDisplayMiqaats] = useState([]);
  const [displaySurats, setDisplaySurats] = useState([]);
  const [displaysubmittedAdaats, setDisplaysubmittedAdaats] = useState([]); //final display
  const [currentAyat, setcurrentAyat] = useState([]);
  const [student, setstudent] = useState();
  const [validated, setValidated] = useState(false);
  const [EditForm, setEditForm] = useState(false);
  const [EditIdADM, setEditIdADM] = useState("");
  const [ReadyToEdit, setReadyToEdit] = useState(false);
  const [isLoding, setisLoding] = useState(false);

  const [uniqueSurahCount, setUniqueSurahCount] = useState(0);

  const [formattedDate, setFormattedDate] = useState("");

  const [toast, addToast] = useState(0);

  
  const { setAuthState } = useContext(AuthContext);
const toaster = useRef()
const exampleToast = (
  <CToast>
    <CToastHeader closeButton>
      <svg
        className="rounded me-2"
        width="20"
        height="20"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid slice"
        focusable="false"
        role="img"
      >
        <rect width="100%" height="100%" fill="#007aff"></rect>
      </svg>
      <div className="fw-bold me-auto">Great!</div>
    </CToastHeader>
    <CToastBody>Form submitted successfully</CToastBody>
  </CToast>
)

const reportView = () => {
  
  navigate("/reports", {
    state: { suratPercentage:student.suratPercentage }
  });
};

  const [formData, setformData] = useState({
    remarkBoxes: {},
    yesno: {},
    customField: [],
    responsetypeCustomField: [],
    images: [],
  });

  const [newformData, setnewformData] = useState({
    images: [],
    category: [
      {
        categoryName: "",
        aadat: [
          {
            aadatId: "",
            aadatName: "",
            remarkBoxes: {},
            yesno: {},
            customField: [],
            responsetypeCustomField: [],
          },
        ],
      },
    ],
  });

  const [newformDatanode, setnewformDataNode] = useState({
    // 
    aadat: [
      {
        aadatId: "",
        aadatName: "",
        remarkBoxes: {},
        yesno: {},
        customField: [],
        responsetypeCustomField: [],
        customType: [],
        images: [],
        isValid:false
      },
    ],
    surat: [
      {
        suratName: "",
        suratId: "",
      },
    ],
    miqaat: [
      {
        miqaatId: "",
        miqaatyesno: ""
      },
    ],
  });

  const [suratForm, setsuratForm] = useState({
    suratName: "",
    ayatNo: "",
    selectedAyatNo: "",
  });

  const [NewsuratForm, setNewsuratForm] = useState({
    suratName: "",
    ayatNo: "",
    selectedAyatNo: "",
  });

  const getsurats = async () => {
    try {
      const res = student;

      // this is the call where you are retrieving all the adaats for the student for repetation- daily
      const response = await axios.get(
        "https://api.myaadat.com/api/v1/users/surat/all",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // params: {
          //   class: res.class,
          //   repetation: "weekly",
          //   applicableTo: res.gender,
          //   currentTime: new Date(),
          // },
        }
      );

      const relength = response.data;
      console.log(response.data);
      if (Object.keys(relength).length === 0) {
        return false;
      }

      setDisplaySurats(response.data.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getMiqaats = async () => {
    try {
      const res = student;

      // this is the call where you are retrieving all the adaats for the student for repetation- daily
      const response = await axios.get(
        "https://api.myaadat.com/api/v1/miqaat",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // params: {
          //   class: res.class,
          //   repetation: "weekly",
          //   applicableTo: res.gender,
          //   currentTime: new Date(),
          // },
        }
      );

      const relength = response.data;
      console.log(response.data);
      if (Object.keys(relength).length === 0) {
        return false;
      }
      let submittedMiqaats = displaysubmittedAdaats.miqaat;
    console.log(submittedMiqaats);
      //let filteredsubmittedAdaats = submittedAdaats.filter((subadaatt) => subadaatt.aadatId === aadatmap._id);

      setDisplayMiqaats(response.data.data.docs);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  
  const getsubmittedData = async () => {
    try{
      const dataForToday = await axios.get(
        // https://api.myaadat.com/api/v1/aadatdata/sumbitresponse?student=${student._id}
        `https://api.myaadat.com/api/v1/aadatdata/sumbitresponse`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(dataForToday.data.data);
      setDisplaysubmittedAdaats(dataForToday.data.data);
      console.log(displaysubmittedAdaats);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getAadatsforStudentweekly = async () => {
    try {
      // this is for getting the information about the student you need to display all the adaats for
      // const res = await axios.get(
      //   `https://api.myaadat.com/api/v1/users/${localStorage.getItem(
      //     "userId"
      //   )}`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );

      // setstudent(res.data.data);

      const res = student;

      // this is the call where you are retrieving all the adaats for the student for repetation- daily
      const response = await axios.get(
        "https://api.myaadat.com/api/v1/aadat/getAllDailyAadat",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            class: res.class,
            repetation: "weekly",
            applicableTo: res.gender,
            currentTime: new Date(),
          },
        }
      );

      const relength = response.data.data;
      console.log(response.data.data);
      if (Object.keys(relength).length === 0) {
        return false;
      }

      console.log(response.data.data[0].repeatDateForYear);

      // Get today's day in lowercase (e.g., 'monday', 'tuesday')

      const today = new Date()
        .toLocaleString("default", { weekday: "long" })
        .toLowerCase();

      const month = new Date()
        .toLocaleString("default", { month: "long" })
        .toLowerCase();

      // Filter aadats based on today's repetition day
      // const filtered = response.data.data.filter((aadat) => {
      //   return (
      //     aadat.repeatDays.some((day) => day.toLowerCase() === today) &&
      //     aadat.repeatMonths.some((mon) => mon.toLowerCase() === month)
      //   );
      // });

      const filtered = response.data.data;

      // checking if this weekly aadats were already submitted
      const todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0); // Set to 00:00:00 of today

      const todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999); // Set to 23:59:59 of today

      // const filtered2 = filtered.filter((aadat) => {
      //   if (!aadatDataModelIds.includes(aadat._id)) {
      //     return aadat;
      //   } else {
      //     const relevantAddmt = aadatDataModelIds1.find(
      //       (addmt) =>
      //         addmt.aadat._id === aadat._id &&
      //         addmt.student === authState.id &&
      //         new Date(addmt.createdAt) >= todayStart && // Check for entries created within today 24hours
      //         new Date(addmt.createdAt) <= todayEnd
      //     );

      //     if (relevantAddmt) {
      //       return false;
      //     } else {
      //       return aadat;
      //     }
      //   }
      // });

      console.log("these are aadats for today- weekly", filtered);
      setadaats(filtered);

      const newWeeklyAadats = filtered;

      // filter for checking endDate
      const currentTime = new Date(); // Current date and time

      // Convert the current date to ISO format
      const currentTimeISO = currentTime.toISOString();

      const FnewWeeklyAadats = newWeeklyAadats.filter((aadat) =>
        aadat.endDate == null ? aadat : aadat.endDate > currentTimeISO
      );

      // Check if the newDailyAadats are not already present in displayAdaats
      const isDuplicate = FnewWeeklyAadats.some((newAadat) =>
        displayAdaats.some((displayAadat) => displayAadat._id === newAadat._id)
      );

      if (!isDuplicate) {
        // If the new data is not a duplicate, merge it into displayAdaats
        const mergedDisplayAdaats = [...displayAdaats, ...FnewWeeklyAadats];
        // setDisplayAdaats(mergedDisplayAdaats);

        //returning to usestate
        return mergedDisplayAdaats;
      }

      // copy pasta for month

      ///////////////////////////
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getAadatsforStudentMonthly = async () => {
    try {
      // this is for getting the information about the student you need to display all the adaats for
      // const res = await axios.get(
      //   `https://api.myaadat.com/api/v1/users/${localStorage.getItem(
      //     "userId"
      //   )}`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );

      // setstudent(res.data.data);

      const res = student;

      // this is the call where you are retrieving all the adaats for the student for repetation- daily
      const response = await axios.get(
        "https://api.myaadat.com/api/v1/aadat/getAllDailyAadat",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            class: res.class,

            repetation: "monthly",
            applicableTo: res.gender,

            currentTime: new Date(),
          },
        }
      );

      // // Get today's month in lowercase (e.g., 'january', 'february')
      // const todayMonth = new Date()
      //   .toLocaleString("default", { month: "long" })
      //   .toLowerCase();

      // // Filter aadats based on today's repetition month
      // const filteredMonthly = response.data.data.filter((aadat) => {
      //   const datefort = aadat.repeatDateForMonth;
      //   console.log(datefort, new Date().getDate());
      //   return (
      //     aadat.repeatMonths.some(
      //       (month) => month.toLowerCase() === todayMonth
      //     ) &&
      //     aadat.repeatDateForMonth.some((rdom) => {
      //       const date = new Date(rdom);
      //       return date.getDate() === new Date().getDate();
      //     })
      //   );
      // });
      const today = new Date();
      const todayMonth = today
        .toLocaleString("default", { month: "long" })
        .toLowerCase();
      // const todayMonth = "november";
      const todayDate = today.getDate();

      console.log(response.data.data);

      // const filteredMonthly = response.data.data.filter((aadat) => {
      //   if (!aadat.repeatDateForMonth || !aadat.repeatMonths) {
      //     return false;
      //   }

      //   const repeatDates = aadat.repeatDateForMonth.map((rdom) =>
      //     new Date(rdom).getDate()
      //   );
      //   const repeatMonths = aadat.repeatMonths.map((month) =>
      //     month.toLowerCase()
      //   );

      //   return (
      //     repeatMonths.includes(todayMonth) && repeatDates.includes(todayDate)
      //   );
      // });

      const filteredMonthly = response.data.data;

      // Checking if these monthly aadats were already submitted
      console.log("These are aadats for today - monthly", filteredMonthly);
      setadaatsM(filteredMonthly); // Assuming setAdaats updates the state with filteredMonthly

      const newMonthlyAadats = filteredMonthly;

      // filter for checking endDate
      const currentTime = new Date(); // Current date and time

      // Convert the current date to ISO format
      const currentTimeISO = currentTime.toISOString();

      const FnewMontlyAadats = newMonthlyAadats.filter((aadat) =>
        aadat.endDate == null ? aadat : aadat.endDate > currentTimeISO
      );

      // Check if the newDailyAadats are not already present in displayAdaats
      const isDuplicate = FnewMontlyAadats.some((newAadat) =>
        displayAdaats.some((displayAadat) => displayAadat._id === newAadat._id)
      );

      if (!isDuplicate) {
        // If the new data is not a duplicate, merge it into displayAdaats
        const mergedDisplayAdaats = [...displayAdaats, ...FnewMontlyAadats];
        // setDisplayAdaats(mergedDisplayAdaats);

        //returning to usestate
        return mergedDisplayAdaats;
      }

      // setaadatdatamodels(response.data.aadatdatamodels);
      // setaadatDataModelIds(response.data.aadatDataModelIds);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // yearly dawg
  const getAadatsforStudentYearly = async () => {
    // this is for getting the information about the student you need to display all the adaats for
    // const res = await axios.get(
    //   `https://api.myaadat.com/api/v1/users/${localStorage.getItem(
    //     "userId"
    //   )}`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );

    // setstudent(res.data.data);

    const res = student;

    // this is the call where you are retrieving all the adaats for the student for repetation- daily
    const response = await axios.get(
      "https://api.myaadat.com/api/v1/aadat/getAllDailyAadat",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          class: res.class,
          repetation: "yearly",
          applicableTo: res.gender,
          currentTime: new Date(),
        },
      }
    );

    const relength = response.data.data;
    console.log(response.data.data);
    if (Object.keys(relength).length === 0) {
      return false;
    }

    console.log(response.data.data[0].repeatDateForYear);

    const today = new Date();
    const todayDate = today.getDate();
    const todayMonth = today.getMonth();
    const responseData = response.data.data;

    const filteredYearly = responseData.filter((aadat) => {
      console.log(aadat.repeatDateForYear);
      // return aadat.repeatDateForYear.some((rdoy) => {
      const date = new Date(aadat.repeatDateForYear);
      const repeatDay = date.getDate();
      const repeatMonth = date.getMonth();

      // Check if the year of today's date is less than the end date year
      const endDate = new Date(aadat.endDate);
      const endYear = endDate.getFullYear();
      if (today.getFullYear() < endYear) {
        // Check if today's month and date match the repeat month and date
        if (todayDate === repeatDay && todayMonth === repeatMonth) {
          // Add any additional conditions or logic here
          return true; // Return true if it meets the conditions
        }
      }
      return true; // Temp modified Return false otherwise
      // });
    });

    // Checking if these monthly aadats were already submitted
    console.log("These are aadats for today - yearly", filteredYearly);
    setadaatsM(filteredYearly); // Assuming setAdaats updates the state with filteredMonthly

    const newMonthlyAadats = filteredYearly;

    // filter for checking endDate
    const currentTime = new Date(); // Current date and time

    // Convert the current date to ISO format
    const currentTimeISO = currentTime.toISOString();

    const FnewYearlyAadats = newMonthlyAadats.filter((aadat) =>
      aadat.endDate == null ? aadat : aadat.endDate > currentTimeISO
    );

    // Check if the newDailyAadats are not already present in displayAdaats
    const isDuplicate = FnewYearlyAadats.some((newAadat) =>
      displayAdaats.some((displayAadat) => displayAadat._id === newAadat._id)
    );

    if (!isDuplicate) {
      // If the new data is not a duplicate, merge it into displayAdaats
      const mergedDisplayAdaats = [...displayAdaats, ...FnewYearlyAadats];
      // setDisplayAdaats(mergedDisplayAdaats);

      //returning to usestate
      return mergedDisplayAdaats;
    }

    // setaadatdatamodels(response.data.aadatdatamodels);
    // setaadatDataModelIds(response.data.aadatDataModelIds);
  };

  // for customized

  const getAadatsforStudentCustom = async () => {
    // this is for getting the information about the student you need to display all the adaats for
    // const res = await axios.get(
    //   `https://api.myaadat.com/api/v1/users/${localStorage.getItem(
    //     "userId"
    //   )}`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );

    // setstudent(res.data.data);

    const res = student;

    console.log(res);

    // this is the call where you are retrieving all the adaats for the student for repetation- daily
    const response = await axios.get(
      "https://api.myaadat.com/api/v1/aadat/getAllDailyAadat",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          class: res.class,
          repetation: "custom", /// custom //// dailty, mothly
          applicableTo: res.gender,
          currentTime: new Date(),
        },
      }
    );

    const today = new Date();
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth();
    const todayDay = today.getDate();
    console.log(response.data.data);
    //Temp modified const filteredCustom = response.data.data.filter((aadat) => {
    //   const customDate = new Date(aadat.customDate);
    //   const customYear = customDate.getFullYear();
    //   const customMonth = customDate.getMonth();
    //   const customDay = customDate.getDate();
    //   console.log(todayYear, todayMonth, todayDay);
    //   console.log(customYear, customMonth, customDay);
    //   return (
    //     todayYear === customYear &&
    //     todayMonth === customMonth &&
    //     todayDay === customDay
    //   );
    // });
    const filteredCustom = response.data.data;

    // Checking if these monthly aadats were already submitted
    console.log("These are aadats for today - custom", filteredCustom);
    setadaatsM(filteredCustom); // Assuming setAdaats updates the state with filteredMonthly

    const newMonthlyAadats = filteredCustom;

    // filter for checking endDate
    const currentTime = new Date(); // Current date and time

    // Convert the current date to ISO format
    const currentTimeISO = currentTime.toISOString();

    const FnewCustomAadats = newMonthlyAadats.filter((aadat) =>
      aadat.endDate == null ? aadat : aadat.endDate > currentTimeISO
    );

    // Check if the newDailyAadats are not already present in displayAdaats
    const isDuplicate = FnewCustomAadats.some((newAadat) =>
      displayAdaats.some((displayAadat) => displayAadat._id === newAadat._id)
    );

    if (!isDuplicate) {
      // If the new data is not a duplicate, merge it into displayAdaats
      const mergedDisplayAdaats = [...displayAdaats, ...FnewCustomAadats];
      // setDisplayAdaats(mergedDisplayAdaats);

      //returning to usestate
      return mergedDisplayAdaats;
    }

    // setaadatdatamodels(response.data.aadatdatamodels);
    // setaadatDataModelIds(response.data.aadatDataModelIds);
  };

  const getAadatsforStudentDaily = async () => {
    // this is for getting the information about the student you need to display all the adaats for
    // const res = await axios.get(
    //   `https://api.myaadat.com/api/v1/users/${localStorage.getItem(
    //     "userId"
    //   )}`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );

    // console.log(res.data.data.class);
    // console.log(res.data.data);

    // setstudent(res.data.data);

    const res = student;
    console.log(student);
    // this is the call where you are retrieving all the adaats for the student for repetation- daily
    const response = await axios.get(
      "https://api.myaadat.com/api/v1/aadat/getAllDailyAadat",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          class: res.class,
          repetation: "daily",
          applicableTo: res.gender,
          currentTime: new Date(),
        },
      }
    );
    const relength = response.data.data;
    console.log(relength);
    if (Object.keys(relength).length === 0) {
      return false;
    }

    console.log(`these are daily`, response.data);
    setadaatsD(response.data.data);

    const newDailyAadats = response.data.data;

    // filter for checking endDate
    const currentTime = new Date(); // Current date and time

    // Convert the current date to ISO format
    const currentTimeISO = currentTime.toISOString();

    // const FnewDailyAadats = newDailyAadats.filter((aadat) =>
    //   aadat.endDate == null ? aadat : aadat.endDate > currentTimeISO
    // );

    const FnewDailyAadats = newDailyAadats;

    console.log(FnewDailyAadats);

    // Check if the newDailyAadats are not already present in displayAdaats
    const isDuplicate = FnewDailyAadats.some((newAadat) =>
      displayAdaats.some((displayAadat) => displayAadat._id === newAadat._id)
    );

    if (!isDuplicate) {
      // If the new data is not a duplicate, merge it into displayAdaats
      const mergedDisplayAdaats = [...displayAdaats, ...FnewDailyAadats];
      // setDisplayAdaats(mergedDisplayAdaats);

      //returning to usestate
      console.log(mergedDisplayAdaats);
      return mergedDisplayAdaats;
    }

    // setaadatDataModelIds1(response.data.aadatDataModelIds);
    // setaadatdatamodels1(response.data.aadatdatamodels);
  };

  // usestates for all types of adaats

  // this one is used for daily
  const [aadatdatamodels1, setaadatdatamodels1] = useState([]);
  const [aadatDataModelIds1, setaadatDataModelIds1] = useState([]);

  // this one is used for weekly
  const [aadatdatamodels, setaadatdatamodels] = useState([]);
  const [aadatDataModelIds, setaadatDataModelIds] = useState([]);

  const [dailyAadats, setdailyAadats] = useState([]);
  const [weeklyAadats, setweeklyAadats] = useState([]);

  const remindUsersdaily = () => {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0); // Set to 00:00:00 of today

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999); // Set to 23:59:59 of today
    const filteredDaily = adaatsD
      .map((aadat) => {
        if (aadatDataModelIds1.includes(aadat._id)) {
          const relevantAddmt = aadatdatamodels1.find(
            (addmt) =>
              addmt.aadat._id === aadat._id && addmt.student === authState.id
            // &&
            // new Date(addmt.createdAt) >= todayStart && // Check for entries created after today's start
            // new Date(addmt.createdAt) <= todayEnd
          );
          if (relevantAddmt) {
            if (
              // checking if the entry was made before todays end

              new Date(relevantAddmt.createdAt) <= todayEnd
            ) {
              // if yes then dont print the aadat
              return null;
            } else {
              // if no print the aadat only if
              return aadat;
            }
            // console.log(
            //   // todayStart,
            //   // new Date(relevantAddmt.createdAt),
            //   // todayEnd

            //   new Date(relevantAddmt.createdAt) <= todayEnd
            // );
          } else {
            return aadat;
          }
          // if (!relevantAddmt) {
          //   return aadat;
          // } else {
          //   return false;
          // }
        } else {
          return aadat;
        }
        // if (relevantAddmt) {
        //   // Check if relevantAddmt was created before the current day's midnight but after yesterday's midnight should not consider
        //   // any before that
        //   if (
        //     relevantAddmt.createdAt < midnightOfgivenTime &&
        //     relevantAddmt.createdAt >= yesterdayStart
        //   ) {
        //     return aadat; // Include aadat if the condition is met
        //   }
        // } else {
        //   return aadat; // Include aadat if there's no relevantAddmt found
        // }
        // return !relevantAddmt; // Include only if not created today
      })
      .filter(Boolean);
    setdailyAadats(filteredDaily);
  };

  // useEffect(() => {
  //   remindUsersdaily();
  //   // remindUsersWeekly();
  // }, [aadatDataModelIds, aadatdatamodels]);

  // useEffect(() => {
  //   getAadatsforStudentCustom();
  //   getAadatsforStudentYearly();
  //   getAadatsforStudentweekly();
  //   getAadatsforStudentMonthly();
  //   getAadatsforStudentDaily();
  // }, [authState.id, authState.role]);

  // effects

  // useEffect(() => {
  //   const fetchAllAadats = async () => {
  //     setDisplayAdaats([]);
  //     const customAadats = await getAadatsforStudentCustom();
  //     const yearlyAadats = await getAadatsforStudentYearly();
  //     const weeklyAadats = await getAadatsforStudentweekly();
  //     const monthlyAadats = await getAadatsforStudentMonthly();
  //     const dailyAadats = await getAadatsforStudentDaily();

  //     // Combine all the fetched aadats
  //     const allAadats = [
  //       ...(customAadats || []), // Check if falsy or empty
  //       ...(yearlyAadats || []),
  //       ...(weeklyAadats || []),
  //       ...(monthlyAadats || []),
  //       ...(dailyAadats || []),
  //     ];
  //     // Set the combined aadats to the displayAadats state
  //     console.log("Display adaats->", allAadats);
  //     setDisplayAdaats(allAadats);
  //   };

  //   fetchAllAadats();
  // }, [authState.id, authState.role]);

  const fetchData = async () => {
    // const customAadats = await getAadatsforStudentCustom();
    // const yearlyAadats = await getAadatsforStudentYearly();
    // const weeklyAadats = await getAadatsforStudentweekly();
    // const monthlyAadats = await getAadatsforStudentMonthly();
    //const dailyAadats = await getAadatsforStudentDaily();

    const [
      customAadats,
      dailyAadats,
      yearlyAadats,
      weeklyAadats,
      monthlyAadats,
    ] = await Promise.all([
      getAadatsforStudentCustom(),
      getAadatsforStudentDaily(),
      getAadatsforStudentYearly(),
      getAadatsforStudentweekly(),
      getAadatsforStudentMonthly(),
    ]);

    // Combine all the fetched aadats into a Set to ensure uniqueness
    // const allAadatsSet = new Set([
    //   ...(customAadats || []),
    //   ...(yearlyAadats || []),
    //   ...(weeklyAadats || []),
    //   ...(monthlyAadats || []),
    //   ...(dailyAadats || []),
    // ]);

    const allAadatsSet = new Set([...(dailyAadats || [])]);

    console.log(allAadatsSet);
    // Convert the Set back to an array
    return Array.from(allAadatsSet);
  };

  const fetchAllAadats = async () => {
    setisLoding(true);
    let submittedAdaats = displaysubmittedAdaats.aadat;
    console.log(submittedAdaats);
    // console.log(students);

    // const studentsvar = students;
    // const allAadats = await fetchData();
    // Set the combined aadats to the displayAadats state after clearing the previous data

    const customAadats = await getAadatsforStudentCustom();
    const yearlyAadats = await getAadatsforStudentYearly();
    const weeklyAadats = await getAadatsforStudentweekly();
    const monthlyAadats = await getAadatsforStudentMonthly();
    const dailyAadats = await getAadatsforStudentDaily();

    // const [customAadats, dailyAadats, yearlyAadats, weeklyAadats, monthlyAadats] =
    //     await Promise.all([
    //         (getAadatsforStudentCustom()),
    //         (getAadatsforStudentDaily()),
    //         (getAadatsforStudentYearly()),
    //         (getAadatsforStudentweekly()),
    //         (getAadatsforStudentMonthly()),
    //     ]);

    // const dailyAadatss = await getAadatsforStudentDaily();

    // Combine all the fetched aadats into a Set to ensure uniqueness
    // const allAadatsSet = new Set([
    //   ...(customAadats || []),
    //   ...(yearlyAadats || []),
    //   ...(weeklyAadats || []),
    //   ...(monthlyAadats || []),
    //   ...(dailyAadats || []),
    // ]);

    const allAadatsSet = new Set([
      ...(customAadats || []),
      ...(dailyAadats || []),
      ...(yearlyAadats || []),
      ...(weeklyAadats || []),
      ...(monthlyAadats || []),
    ]);

    //console.log(allAadatsSet);
    // Convert the Set back to an array
    const allAadats = Array.from(allAadatsSet);

    const modifiedaadat = allAadats.map((aadatmap, index)=>{
     // console.log(aadatmap);

     let aadatTemp = aadatmap;
     
    // if(aadatmap._id!='6672872b1b0b90ceb659fb73') return;
     console.log(aadatmap._id);

     let filteredsubmittedAdaats = submittedAdaats.filter((subadaatt) => subadaatt.aadatId === aadatmap._id);

     let filteredtemp = filteredsubmittedAdaats[0];
     console.log('filteredtemp', filteredsubmittedAdaats[0]);
     if(!filteredtemp){return {...aadatmap}}

     
    let responsevalues=[];
    let aadatMaster;
     aadatmap.responseType.map((type, typeIndex) => {
      switch (type) {
        case "yesno":
          if(filteredtemp.yesno){
          console.log(filteredtemp.yesno);
          let yesnovalue;
         if(filteredtemp.yesno=='yes'){
          yesnovalue = 'true';
         }else{
          yesnovalue = 'false';
         }
          responsevalues['yesno']= yesnovalue;
        }
        break;
        case "custom":
        console.log(type);
        console.log(filteredtemp.customType);
        responsevalues['custom']=filteredtemp.customType;
        break;
        case "surat":
          console.log(type);
          if(filteredtemp.suratId){
            var suratId = filteredtemp.suratId;
            var suratName = filteredtemp.suratName;
            console.log(suratId);
            responsevalues['suratid']= suratId;
            responsevalues['suratName']= suratName;
          }
          break;
        case "remarkbox":
          console.log(type, filteredtemp.remarkBoxes['remarkbox_1']);
          if(filteredtemp.remarkBoxes && filteredtemp.remarkBoxes['remarkbox_1']){
            var remarboxvalue = filteredtemp.remarkBoxes['remarkbox_1'].value;
            console.log(remarboxvalue);
            responsevalues['remarkbox']= remarboxvalue;
          }
        break;
        case "image":
          console.log(filteredtemp.images);
          responsevalues['images']=filteredtemp.images;
          console.log(responsevalues['images'])
        default:
          return null;
      }
     });

     const modifiedCustomfield = aadatmap.customField.map((field, fieldIndex) => {
      console.log(filteredtemp.customField.length);
     
      if(filteredtemp.customField.length >0 && field.fieldType === "dropdown"){
        console.log(filteredtemp);
        var fieldvalue = filteredtemp.customField[0][field.fieldTitle].value;
        var fieldTitle = field.fieldTitle;
        console.log(fieldTitle, fieldvalue);
        // responsevalues['customField']= [];
        // responsevalues['customField'][fieldTitle] =fieldvalue;
        
        return {...field,defaultvalue:fieldvalue}
        
      }
      else{
        return field;
      }
      // field.fieldType === "dropdown"

     })

     console.log(modifiedCustomfield);
     console.log(responsevalues);
     let FinalAadatKey = {...aadatmap}
     FinalAadatKey.customField = modifiedCustomfield;

     console.log(FinalAadatKey);

     return {...FinalAadatKey,responsevalues}



     //console.log(filteredsubmittedAdaats);
     
      // submittedAdaats.map((submittedadaat, index) => {
      //   console.log(submittedadaat.aadatId);
      //   if(submittedadaat.aadatId == '6672872b1b0b90ceb659fb73'){

      //   }
      //  // console.log(submittedadaat); 
      // });
    })

    console.log('modifiedaadat', modifiedaadat);

    console.log("Display adaats here->", allAadats);
    if(submittedAdaats.length==0){
    console.log("displaysubmittedAdaats Length 0");
    setDisplayAdaats(allAadats);
    setisLoding(false);
    }else{
    console.log("displaysubmittedAdaats Length is not 0");
    setDisplayAdaats(modifiedaadat);
    setisLoding(false);
    }
  };

  const reloadDetails = async () =>{
    axios
      .get(
        `https://api.myaadat.com/api/v1/users/${localStorage.getItem(
          "userId"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setstudent(response.data.data);
      });
  }

  useEffect(() => {
    axios
      .get(
        `https://api.myaadat.com/api/v1/users/${localStorage.getItem(
          "userId"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setstudent(response.data.data);
        setAuthState({
          role: response.data.data.role,
          id: response.data.data._id,
          name: response.data.data.firstName,
          lastname: response.data.data.lastName
        });
      });
  }, []);

  useEffect(() => {
    if (!student) return;
    console.log(student);
    getsubmittedData();
  }, [student]);

  useEffect(()=>{
    console.log('displaysubmittedAdaats', displaysubmittedAdaats);
    if(displaysubmittedAdaats.length==0) return;
    fetchAllAadats();
    getMiqaats();
    getsurats();
  },[student, displaysubmittedAdaats])

  //Temp modified useEffect(() => {
  //   fetchAllAadats();
  // }, [authState.id, authState.role]);

  // useEffect(() => {
  //   fetchAllAadats();
  // }, []);
  useEffect(() => {
    const fnccc = async () => {
      // Your code here...
      const userId = localStorage.getItem("userId");

      const dataForToday = await axios.get(
        `https://api.myaadat.com/api/v1/aadatdata/sumbitresponse?student=${userId}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(dataForToday.data.data.docs);
      const datafortoday = dataForToday.data.data.docs;
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set to 00:00:00 of today

      const filteredData = datafortoday?.filter((aadat) => {
        const aDate = new Date(aadat.createdAt);

        return aDate >= today;
      });

      console.log(filteredData);
      // 08/12/2023
      // before submitting the form i have to check if the form was already submitted for today,

      // it is submitted=====> print the values to form display
      if (filteredData.length > 0) {
        const [firstElement] = filteredData; // Extracting the first element

        // extracting data singularly
        // setformData({
        //   remarkBoxes: firstElement.remarkBoxes,
        //   yesno: firstElement.yesno,
        //   customField: firstElement.customField,
        //   responsetypeCustomField: firstElement.responsetypeCustomField,
        // });

        // form editing should be handled here
        setEditIdADM(firstElement._id);
        // setEditForm(true);
        setReadyToEdit(true);
      }

      // ////////////////////////////////////setting up the latest surat student has read
    };

    // fnccc();
  }, []);

  useEffect(() => {
    if (student && student.suratRecord) {
      const countUniqueEntries = async () => {
        // extracting latest surat student has read

        const suratRecordArray = student.suratRecord;

        // Get the last entry from the suratRecord array
        const lastEntry = suratRecordArray[suratRecordArray.length - 1];

        if (lastEntry) {
          setsuratForm((prevSuratForm) => ({
            ...prevSuratForm,
            suratName: lastEntry.suratName,
            ayatNo: ayatsPerSurat[lastEntry.suratName] || prevSuratForm.ayatNo,
            selectedAyatNo: lastEntry.selectedAyatNo,
          }));
        }

        // const uniqueEntries = new Set();
        // let count = 0;

        // Object.entries(student.suratRecord).forEach(
        //   ([suratName, selectedAyatNo]) => {
        //     const combinedEntry = `${suratName}-${selectedAyatNo}`;

        //     if (!uniqueEntries.has(combinedEntry)) {
        //       uniqueEntries.add(combinedEntry);
        //       count++;
        //     }
        //   }
        // );
        // // 6236 total ayats
        // setUniqueSurahCount(count);

        try {
          const response = await axios.get(
            "https://api.myaadat.com/api/v1/surat",
            {
              params: {
                suratName: lastEntry.suratName,
                ayatNo: 1,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Assuming the page number is available in the response data
          const pageNumber = response.data.pageNumber;
          // console.log(lastEntry.suratName);
          // console.log(pageNumber);

          setUniqueSurahCount(pageNumber);
        } catch (error) {
          console.error("Error fetching data:", error.message);
          // Handle errors here
        }
      };

      countUniqueEntries();
    }
  }, [student]);

  useEffect(() => {
    function formatDate(date) {
      const options = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      };
      return new Date(date).toLocaleDateString("en-US", options);
    }

    // Get the current date
    const currentDate = new Date();
    const formatted = formatDate(currentDate);

    // Update the state with the formatted date
    setFormattedDate(formatted);
  }, []);

  if (!student) {
    return <div>Loading...</div>; //setting loading to avoid onload error
  }

  // const handlechangeCusres = (e, val, cusresTitle) => {
  //   const { name, checked } = e.target;
  //   const updatedResponsetypeCustomField = [
  //     ...formData.responsetypeCustomField,
  //   ];

  //   updatedResponsetypeCustomField[name].cusresTitle = cusresTitle;
  //   updatedResponsetypeCustomField[name].cusresValue[val] = checked;
  // };
  // const handlechangeCusres = (e, val, cusresTitle) => {
  //   const { name, checked } = e.target;

  //   if (name.startsWith("cusradiofortitle")) {
  //     const [categoryIndex, index, typeIndex, resIndex, cusresindexVal] =
  //       name.split("_");

  //     if (name.startsWith("cusradiofortitle")) {
  //       console.log(name, checked);

  //       const updatedResponsetypeCustomField = [
  //         ...formData.responsetypeCustomField,
  //       ];

  //       if (!updatedResponsetypeCustomField[index]) {
  //         updatedResponsetypeCustomField[index] = [];
  //       }

  //       if (!updatedResponsetypeCustomField[index][resIndex]) {
  //         updatedResponsetypeCustomField[index][resIndex] = {
  //           cusresTitle: cusresTitle,
  //           cusresValue: {},
  //         };
  //       }

  //       if (!updatedResponsetypeCustomField[index][resIndex].cusresValue) {
  //         updatedResponsetypeCustomField[index][resIndex].cusresValue = {}; // Initialize cusresValue if it's not defined
  //       }
  //       updatedResponsetypeCustomField[index][resIndex].cusresTitle =
  //         cusresTitle;

  //       if (
  //         !updatedResponsetypeCustomField[index][resIndex].cusresValue[name]
  //       ) {
  //         updatedResponsetypeCustomField[index][resIndex].cusresValue[name] =
  //           {}; // Initialize cusresindexVal if it's not defined
  //       }

  //       if (checked) {
  //         updatedResponsetypeCustomField[index][resIndex].cusresValue[name][
  //           val
  //         ] = checked;
  //       } else {
  //         updatedResponsetypeCustomField[index][resIndex].cusresValue[name][
  //           val
  //         ] = false; // Set the value to false if checked is false
  //       }

  //       setformData({
  //         ...formData,
  //         responsetypeCustomField: updatedResponsetypeCustomField,
  //       });
  //     }
  //   }
  // };

  const handlechangeCusres = (e, val, cusresTitle) => {
    const { name } = e.target;
    const [categoryIndex, index, typeIndex, resIndex, cusresindexVal] =
      name.split("_");

    // Update the selected value for the group

    // const updatedvalue = {
    //   [`${categoryIndex}_${index}_${typeIndex}_${resIndex}`]: val,
    // };
    const existingField =
      formData.responsetypeCustomField[
      `${categoryIndex}_${index}_${typeIndex}_${resIndex}`
      ];
    const updatedValue = {
      ...formData.responsetypeCustomField,
      [`${categoryIndex}_${index}_${typeIndex}_${resIndex}`]: {
        ...(existingField || {}), // If the field already exists, spread its content
        [`${categoryIndex}_${index}_${typeIndex}_${resIndex}`]: val, // Set the new value
      },
    };

    setformData({
      ...formData,
      responsetypeCustomField: updatedValue,
    });
  };

  const handlechange = (e, aadat) => {
    const { name, value } = e.target;

    if (name.startsWith("image")) {
      // const files = e.target.files;
      // If no files are selected, clear the existing state for that input

      const selectedFiles = e.target.files[0];
      console.log(selectedFiles);
      setnewformDataNode({ ...newformDatanode, images: selectedFiles });
    } else if (name.startsWith("yesno")) {
      console.log(name, value);

      setnewformDataNode((prevFormData) => {
        // Check if aadat already exists in the category
                      console.log(prevFormData);
                      const existingAadat = prevFormData.aadat.find(
                        (item) => item.aadatName === aadat.name
                      );
        
                      console.log(existingAadat);
        
                      // If aadat exists, update its 'yesno' object
                      if (existingAadat) {
                        const updatedAadats = prevFormData.aadat.map((item) => {
                          if (item.aadatId === aadat._id) {
                            return {
                              ...item,
                              yesno: value,
                            };
                          }
                          return item;
                        });
        
                        return {
                          ...prevFormData,
                          aadat: updatedAadats,
                        };
                      } else {
                        // If aadat doesn't exist, add a new one
                        return {
                          ...prevFormData,
                          aadat: [
                            ...prevFormData.aadat,
                            {
                              aadatId: aadat._id,
                              aadatName: aadat.name,
                              yesno: value,
                            },
                          ],
                        };
                      }
                    
            });

      // {
      //   category: [
      //     {
      //       categoryName: "",
      //       aadat: [
      //         {
      //           aadatName: "",
      //           remarkBoxes: {},
      //           yesno: {},
      //           customField: [],
      //           responsetypeCustomField: [],
      //           images: [],
      //         },
      //       ],
      //     },
      //   ],
      // }

      // const updatedYesNo = {
      //   ...newformData,
      //   // [name]: {
      //   //   value: value,
      //   //   aadat: aadat._id,
      //   //   aadatName: aadat.name,
      //   //   categoryName: aadat.category.name,
      //   // },
      //   category: [
      //     {
      //       categoryName: aadat.category.name,
      //       aadat: [
      //         {
      //           aadatName: aadat.name,

      //           yesno: {
      //             [name]: {
      //               value: value,
      //             },
      //           },
      //         },
      //       ],
      //     },
      //   ],
      // };

      // setnewformData({
      //   ...newformData,
      //   category: updatedYesNo,
      // });
    } else if (name.startsWith("remarkbox")) {
      setnewformDataNode((prevFormData) => {
        console.log(prevFormData);

        // Check if aadat already exists in the category
        const existingAadat = prevFormData.aadat.find(
          (item) => item.aadatId === aadat._id
        );
        console.log(existingAadat);

        // If aadat exists, update its 'yesno' object
        if (existingAadat) {
          const updatedAadats = prevFormData.aadat.map((item) => {
            if (item.aadatId === aadat._id) {
              return {
                ...item,
                remarkBoxes: {
                  ...item.remarkBoxes,
                  ['remarkbox_1']: {
                    value: value,
                  },
                },
              };
            }
            return item;
          });

          return {
            ...prevFormData,
            aadat: updatedAadats,
          };
        } else {
          // If aadat doesn't exist, add a new one
          return {
            ...prevFormData,
            aadat: [
              ...prevFormData.aadat,
              {
                aadatId: aadat._id,
                aadatName: aadat.name,
                remarkBoxes: {
                  ['remarkbox_1']: {
                    value: value,
                  },
                },
              },
            ],
          };
        }
      });

      // console.log(name, value);
      // const updatedRemarkBoxes = {
      //   ...formData.remarkBoxes,
      //   [name]: {
      //     value: value,
      //     aadat: aadat._id,
      //     aadatName: aadat.name,
      //     categoryName: aadat.category.name,
      //   },
      // };

      // setformData({
      //   ...formData,
      //   remarkBoxes: updatedRemarkBoxes,
      // });
    }
  };

  const handleImagechange = async (e, aadat) => {
    const { name, value } = e.target;

    if (name.startsWith("image")) {
      // const files = e.target.files;
      // If no files are selected, clear the existing state for that input

      

      const selectedFiles = e.target.files;
      console.log(selectedFiles);

     

      

      const ImgFormdata = new FormData();

    //  ImgFormdata.append(`images`, selectedFiles);

      [...selectedFiles].forEach(image => {
        ImgFormdata.append("image", image);
    });

      //ImgFormdata.append

      const response = await axios.post(
        `https://api.myaadat.com/api/v1/aadatdata/uploadImage`,
        ImgFormdata,
        // {
        //   student: authState.id,
        //   remarkBoxes: formData.remarkBoxes,
        //   yesno: formData.yesno,
        //   customField: formData.customField,
        //   responsetypeCustomField: formData.responsetypeCustomField,
        //   images: formData.images,
        // },

        {
          headers: {
            // "Content-Type": "multipart/form-data", // Ensure Content-Type is set to multipart/form-data
            Authorization: `Bearer ${token}`,
          },
        }
      ).then(function (response) {
        let Tempfiles = response.data.imagesArr;
        console.log(Tempfiles);
        // setnewformDataNode({ ...newformDatanode, images: Tempfiles });

        setnewformDataNode((prevFormData) => {
          console.log(prevFormData);
  
          // Check if aadat already exists in the category
          const existingAadat = prevFormData.aadat.find(
            (item) => item.aadatId === aadat._id
          );
          console.log(existingAadat);
  
          // If aadat exists, update its 'yesno' object
          if (existingAadat) {
            const updatedAadats = prevFormData.aadat.map((item) => {
              if (item.aadatId === aadat._id) {
                return {
                  ...item,
                  images: Tempfiles,
                };
              }
              return item;
            });
  
            return {
              ...prevFormData,
              aadat: updatedAadats,
            };
          } else {
            // If aadat doesn't exist, add a new one
            return {
              ...prevFormData,
              aadat: [
                ...prevFormData.aadat,
                {
                  aadatId: aadat._id,
                  aadatName: aadat.name,
                  images: Tempfiles,
                },
              ],
            };
          }
        });

      });




      //setnewformDataNode({ ...newformDatanode, images: selectedFiles });
    }
  };

  const handlemiqaatchange = (e, miqaat) => {
    const { name, value } = e.target;

    console.log(miqaat);
    

      console.log(name, value);

      setnewformDataNode((prevFormData) => {
        // Check if aadat already exists in the category
                      console.log(prevFormData);
                      const existingAadat = prevFormData.miqaat.find(
                        (item) => item.miqaatId === miqaat._id
                      );
        
                      console.log(existingAadat);
                      
        
                      // If aadat exists, update its 'yesno' object
                      if (existingAadat) {
                        const updatedAadats = prevFormData.miqaat.map((item) => {
                          if (item.miqaatId === miqaat._id) {
                            item.miqaatyesno = value;
                            return item;
                          }
                          return item;
                        });
        
                        return {
                          ...prevFormData,
                          miqaat: updatedAadats,
                        };
                      } else {
                        // If aadat doesn't exist, add a new one
                        return {
                          ...prevFormData,
                          miqaat: [
                            ...prevFormData.miqaat,
                            {
                              miqaatId: miqaat._id,
                              miqaatyesno: value
                            },
                          ],
                        };
                      }
                    
            });

  };


  // saving data and making adaatDatamodels for everything mentioned in form

  const saveData = async (event) => {
    const form = event.currentTarget;
    // Check form validity
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      // Handle invalid form case (display errors or take other actions)
      setValidated(true); // If you want to set a flag for showing errors
      return; // Exit function if form is invalid
    }

    // If form is valid
    event.preventDefault();
    event.stopPropagation();

    try {
      console.log(student);

      // Temp modified const dataForToday = await axios.get(
      //   `https://api.myaadat.com/api/v1/aadatdata/sumbitresponse?student=${student._id}`,

      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );

      // console.log(dataForToday.data.data.docs);
      // const datafortoday = dataForToday.data.data.docs;
      // const today = new Date();
      // today.setHours(0, 0, 0, 0); // Set to 00:00:00 of today

      // const filteredData = datafortoday?.filter((aadat) => {
      //   const aDate = new Date(aadat.createdAt);

      //   return aDate >= today;
      // });
      const filteredData = [];
      console.log(filteredData);
      // 08/12/2023
      // before submitting the form i have to check if the form was already submitted for today,

      // it is submitted=====> print the values to form display
      if (filteredData.length > 0) {
        const [firstElement] = filteredData; // Extracting the first element
        // extracting data singularly
        setformData({
          remarkBoxes: firstElement.remarkBoxes,
          yesno: firstElement.yesno,
          customField: firstElement.customField,
          responsetypeCustomField: firstElement.responsetypeCustomField,
        });

        // form editing should be handled here
        setEditIdADM(firstElement._id);
        setEditForm(true);
        setReadyToEdit(true);
      } else {
        // its not submitted========> submit the form ie below

        const Fd = new FormData();

        console.log(newformDatanode);

        // Append form data fields to the FormData object
      //  Fd.append("student", student._id);
        // Fd.append("aadat", JSON.stringify(newformDatanode.aadat));
        // Fd.append("yesno", JSON.stringify(newformData.yesno));
        // Fd.append("customField", JSON.stringify(newformData.customField));
        // Fd.append(
        //   "responsetypeCustomField",
        //   JSON.stringify(newformData.responsetypeCustomField)
        // );

        console.log(Fd);

        // Append files to the FormData object
        // Fd.append("images", formData.images[0]);

        // newformData.images.forEach((file, index) => {
        //   console.log(file);
        //   Fd.append(`images`, file); // Append each file as an array
        //   // Fd.append(`images`, file, file.filename);
        // });

        //Fd.append("aadat", JSON.stringify(newformDatanode.aadat));

        const response = await axios.post(
          `https://api.myaadat.com/api/v1/aadatdata/sumbitresponse`,
          newformDatanode,
          // {
          //   student: authState.id,
          //   remarkBoxes: formData.remarkBoxes,
          //   yesno: formData.yesno,
          //   customField: formData.customField,
          //   responsetypeCustomField: formData.responsetypeCustomField,
          //   images: formData.images,
          // },

          {
            headers: {
              // "Content-Type": "multipart/form-data", // Ensure Content-Type is set to multipart/form-data
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(response);

        addToast(exampleToast);

        setTimeout(function () {
          reloadDetails();
        },500)

        // updating surat
        // const existingSuratRecord = student.suratRecord;

        // existingSuratRecord.push({
        //   suratName: suratForm.suratName,
        //   selectedAyatNo: suratForm.selectedAyatNo,
        // });

        // Creating the requestBody object with the updated suratRecord
        // const requestBody = {
        //   suratRecord: existingSuratRecord, // Converting Map back to an object
        // };

        // const response2 = await axios.patch(
        //   `https://api.myaadat.com/api/v1/users/${authState.id}`,

        //   requestBody,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${token}`,
        //     },
        //   }
        // );
      //  console.log(response2);
       // throw alert("form successfully submitted!");
      }
      setValidated(false); // Reset validation flag if needed
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const uniqueCategories = []; //   ...new Set(displayAdaats.map((adaat) => adaat.category.name)),

  //  console.log(displayAdaats);

  displayAdaats.map((adaat) => {
    if (uniqueCategories.includes(adaat.category.name)) return;
    uniqueCategories.push(adaat.category.name);
  });

  const updateSurat = (
    selectedOption,
    aadat
  ) => {
    console.log(aadat);
    setnewformDataNode((prevFormData) => {
      console.log(prevFormData);

      // Check if aadat already exists in the category
      const existingAadat = prevFormData.aadat.find(
        (item) => item.aadatId === aadat._id
      );
      console.log(existingAadat);

      // If aadat exists, update its 'yesno' object
      if (existingAadat) {
        const updatedAadats = prevFormData.aadat.map((item) => {
          if (item.aadatId === aadat._id) {
            return {
              ...item,
              suratId: selectedOption
            };
          }
          return item;
        });

        return {
          ...prevFormData,
          aadat: updatedAadats,
        };
      } else {
        // If aadat doesn't exist, add a new one
        return {
          ...prevFormData,
          aadat: [
            ...prevFormData.aadat,
            {
              aadatId: aadat._id,
              aadatName: aadat.name,
              suratId: selectedOption
            },
          ],
        };
      }
    });
  };

  const updateCustomFieldDropdown = (
    uniqueIndex,
    fieldTitle,
    fieldType,
    selectedOption,
    aadat
  ) => {
    console.log(aadat);
    setnewformDataNode((prevFormData) => {
      const updatedCustomField = [prevFormData.customField];
      const [fieldIndex, typeIndex, uniqueFieldTitle] = uniqueIndex.split("_");

      // Check if the fieldIndex exists and uniqueFieldTitle matches with fieldTitle
      // const existingField = updatedCustomField.find(
      //   (cf) => cf.adaatId === id && cf.fieldTitle === uniqueFieldTitle
      // );

      // Check if aadat already exists in the category
      const existingAadat = prevFormData.aadat.find(
        (item) => item.aadatName === aadat.name
      );
      console.log(existingAadat);
      // If aadat exists, update its 'yesno' object
      if (existingAadat) {
        const updatedAadats = prevFormData.aadat.map((item) => {
          if (item.aadatId === aadat._id) {
            return {
              ...item,
              customField: {
                ...item.customField,
                [fieldTitle]: {
                  value: selectedOption,
                },
              },
            };
          }
          return item;
        });

        return {
          ...prevFormData,
          aadat: updatedAadats,
        };
      } else {
        // If aadat doesn't exist, add a new one
        return {
          ...prevFormData,
          aadat: [
            ...prevFormData.aadat,
            {
              aadatId: aadat._id,
              aadatName: aadat.name,
              customField: {
                [fieldTitle]: {
                  value: selectedOption,
                },
              },
            },
          ],
        };
      }

      // return {
      //   images: [],
      //   category: [
      //     {
      //       categoryName: "",
      //       aadat: [
      //         {
      //           aadatName: "",
      //           remarkBoxes: {},
      //           yesno: {},
      //           customField: [],
      //           responsetypeCustomField: [],
      //         },
      //       ],
      //     },
      //   ],
      // }

      // if (!existingField) {
      //   // If the field doesn't exist, create a new entry
      //   updatedCustomField.push({
      //     fieldTitle: uniqueFieldTitle,
      //     fieldType: [{ type: fieldType }],
      //     options: [selectedOption],
      //     adaatId: id,
      //   });
      //   console.log(updatedCustomField);
      // } else {
      //   // If the field exists, update its options array
      //   existingField.options = [selectedOption];
      // }

      // setformData({
      //   ...formData,
      //   customField: updatedCustomField,
      // });
    });
  };

  const updateCustomFieldCheckbox = (
    uniqueIndex,
    fieldTitle,
    fieldType,
    selectedOption,
    aadat
  ) => {
    console.log(selectedOption);
    console.log(fieldTitle);
    const { value, checked } = selectedOption;
    setnewformDataNode((prevFormData) => {
      const updatedCustomField = [prevFormData.customField];
      const [fieldIndex, typeIndex, uniqueFieldTitle] = uniqueIndex.split("_");

      // Check if the fieldIndex exists and uniqueFieldTitle matches with fieldTitle
      // const existingField = updatedCustomField.find(
      //   (cf) => cf.adaatId === id && cf.fieldTitle === uniqueFieldTitle
      // );

      // Check if aadat already exists in the category
      const existingAadat = prevFormData.aadat.find(
        (item) => item.aadatName === aadat.name
      );
      console.log(existingAadat);
      // If aadat exists, update its 'yesno' object
      if (existingAadat) {
        const updatedAadats = prevFormData.aadat.map((item) => {
          if (item.aadatId === aadat._id) {
            // console.log(item.customField[fieldTitle].value);

            console.log(item.customField[fieldTitle]);
            var newcheckboxarray = [];
            if(item.customField[fieldTitle]===undefined) {
              console.log("undefined");
              newcheckboxarray.push(value);
            }else{
            newcheckboxarray = [...item.customField[fieldTitle].value];
            
            console.log(newcheckboxarray)
            if (checked) {
              newcheckboxarray.push(value);
            } else {
              newcheckboxarray = newcheckboxarray.filter((e) => {
                console.log(e);
                return e !== value;
              });
            }
            console.log(newcheckboxarray);
            }
            return {
              ...item,
              customField: {
                ...item.customField,
                [fieldTitle]: {
                  value: newcheckboxarray,
                },
              },
            };
          }
          return item;
        });

        return {
          ...prevFormData,
          aadat: updatedAadats,
        };
      } else {
        // If aadat doesn't exist, add a new one

        let newcheckboxarray = [];
        newcheckboxarray.push(value);
        console.log(newcheckboxarray);
        return {
          ...prevFormData,
          aadat: [
            ...prevFormData.aadat,
            {
              aadatId: aadat._id,
              aadatName: aadat.name,
              customField: {
                [fieldTitle]: {
                  value: newcheckboxarray,
                },
              },
            },
          ],
        };
      }

      // return {
      //   images: [],
      //   category: [
      //     {
      //       categoryName: "",
      //       aadat: [
      //         {
      //           aadatName: "",
      //           remarkBoxes: {},
      //           yesno: {},
      //           customField: [],
      //           responsetypeCustomField: [],
      //         },
      //       ],
      //     },
      //   ],
      // }

      // if (!existingField) {
      //   // If the field doesn't exist, create a new entry
      //   updatedCustomField.push({
      //     fieldTitle: uniqueFieldTitle,
      //     fieldType: [{ type: fieldType }],
      //     options: [selectedOption],
      //     adaatId: id,
      //   });
      //   console.log(updatedCustomField);
      // } else {
      //   // If the field exists, update its options array
      //   existingField.options = [selectedOption];
      // }

      // setformData({
      //   ...formData,
      //   customField: updatedCustomField,
      // });
    });
  };

  const updateCustomTypeCheckbox = (
    uniqueIndex,
    fieldTitle,
    fieldType,
    selectedOption,
    aadat
  ) => {
    
    console.log(selectedOption);
    const { value, checked } = selectedOption;
    setnewformDataNode((prevFormData) => {
      const updatedCustomField = [prevFormData.customField];
      const [fieldIndex, typeIndex, uniqueFieldTitle] = uniqueIndex.split("_");

      // Check if the fieldIndex exists and uniqueFieldTitle matches with fieldTitle
      // const existingField = updatedCustomField.find(
      //   (cf) => cf.adaatId === id && cf.fieldTitle === uniqueFieldTitle
      // );

      // Check if aadat already exists in the category
      const existingAadat = prevFormData.aadat.find(
        (item) => item.aadatName === aadat.name
      );
      console.log(existingAadat);

      var adaatdisplay = displayAdaats
                    .map((adaat, index) => {
                      if(adaat._id == aadat._id && adaat.responsevalues){
                      console.log(adaat.responsevalues);
                      
                      var adaatcustom = value
                      adaat.responsevalues.custom = [value]
                      console.log(adaat.responsevalues);
                      }
                      return adaat;
                    })

            setDisplayAdaats(adaatdisplay);
      // If aadat exists, update its 'yesno' object
      if (existingAadat) {
        const updatedAadats = prevFormData.aadat.map((item) => {
          if (item.aadatId === aadat._id) {
            console.log(item.customType);

            

            let newcheckboxarray = [];
            console.log(newcheckboxarray);
            
            
            if (checked) {
              console.log(checked);
              newcheckboxarray.push(value);
              console.log(newcheckboxarray);
            } else {
              console.log(checked);
              // alert('else');
              console.log("else", newcheckboxarray);
              let newcheckboxarrays = newcheckboxarray.filter((e) => {
                console.log(e);
                return e !== value;
              });

              console.log(newcheckboxarrays);

              newcheckboxarray = newcheckboxarrays;

              // newcheckboxarray.push(value);
            }
            console.log(newcheckboxarray);
            console.log(prevFormData);
            return {
              ...item,
              customType: newcheckboxarray,
              isValid: true
            };
          }

          return item;
        });

        return {
          ...prevFormData,
          aadat: updatedAadats,
        };
      } else {
        // If aadat doesn't exist, add a new one

        let newcheckboxarray = [];
        newcheckboxarray.push(value);
        console.log(newcheckboxarray);
        
        console.log(prevFormData);
        return {
          ...prevFormData,
          aadat: [
            ...prevFormData.aadat,
            {
              aadatId: aadat._id,
              aadatName: aadat.name,
              customType: newcheckboxarray,
              isValid: true
            },
          ],
        };
      }

      

      // return {
      //   images: [],
      //   category: [
      //     {
      //       categoryName: "",
      //       aadat: [
      //         {
      //           aadatName: "",
      //           remarkBoxes: {},
      //           yesno: {},
      //           customField: [],
      //           responsetypeCustomField: [],
      //         },
      //       ],
      //     },
      //   ],
      // }

      // if (!existingField) {
      //   // If the field doesn't exist, create a new entry
      //   updatedCustomField.push({
      //     fieldTitle: uniqueFieldTitle,
      //     fieldType: [{ type: fieldType }],
      //     options: [selectedOption],
      //     adaatId: id,
      //   });
      //   console.log(updatedCustomField);
      // } else {
      //   // If the field exists, update its options array
      //   existingField.options = [selectedOption];
      // }

      // setformData({
      //   ...formData,
      //   customField: updatedCustomField,
      // });
    });
  };

  const ayatsPerSurat = {
    "Al-Fatihah": 7,
    "Al-Baqarah": 286,
    "Aali 'Imran": 200,
    "An-Nisa": 176,
    "Al-Ma'idah": 120,
    "Al-An'am": 165,
    "Al-A'raf": 206,
    "Al-Anfal": 75,
    "At-Tawbah": 129,
    Yunus: 109,
    Hud: 123,
    Yusuf: 111,
    "Ar-Ra'd": 43,
    Ibrahim: 52,
    "Al-Hijr": 99,
    "An-Nahl": 128,
    "Al-Isra": 111,
    "Al-Kahf": 110,
    Maryam: 98,
    "Ta-Ha": 135,
    "Al-Anbiya": 112,
    "Al-Hajj": 78,
    "Al-Mu'minun": 118,
    "An-Nur": 64,
    "Al-Furqan": 77,
    "Ash-Shu'ara": 227,
    "An-Naml": 93,
    "Al-Qasas": 88,
    "Al-Ankabut": 69,
    "Ar-Rum": 60,
    Luqmaan: 34,
    "As-Sajdah": 30,
    "Al-Ahzaab": 73,
    "Saba (surah)": 54,
    Faatir: 45,
    "Ya-Sin": 83,
    "As-Saaffaat": 182,
    Saad: 88,
    "Az-Zumar": 75,
    Ghafir: 85,
    Fussilat: 54,
    "Ash-Shura": 53,
    "Az-Zukhruf": 89,
    "Ad-Dukhaan": 59,
    "Al-Jaathiyah": 37,
    "Al-Ahqaaf": 35,
    Muhammad: 38,
    "Al-Fath": 29,
    "Al-Hujuraat": 18,
    Qaaf: 45,
    "Adh-Dhaariyaat": 60,
    "At-Toor": 49,
    "An-Najm": 62,
    "Al-Qamar": 55,
    "Ar-Rahman": 78,
    "Al-Waqi'ah": 96,
    "Al-Hadeed": 29,
    "Al-Mujadila": 22,
    "Al-Hashr": 24,
    "Al-Mumtahanah": 13,
    "As-Saff": 14,
    "Al-Jumu'ah": 11,
    "Al-Munafiqoon": 11,
    "At-Taghabun": 18,
    "At-Talaq": 12,
    "At-Tahreem": 12,
    "Al-Mulk": 30,
    "Al-Qalam": 52,
    "Al-Haaqqa": 52,
    "Al-Ma'aarij": 44,
    Nuh: 28,
    "Al-Jinn": 28,
    "Al-Muzzammil": 20,
    "Al-Muddaththir": 56,
    "Al-Qiyamah": 40,
    "Al-Insaan": 31,
    "Al-Mursalaat": 50,
    "An-Naba'": 40,
    "An-Naazi'aat": 46,
    Abasa: 42,
    "At-Takweer": 29,
    "Al-Infitar": 19,
    "Al-Mutaffifeen": 36,
    "Al-Inshiqaaq": 25,
    "Al-Burooj": 22,
    "At-Taariq": 17,
    "Al-A'la": 19,
    "Al-Ghaashiyah": 26,
    "Al-Fajr": 30,
    "Al-Balad": 20,
    "Ash-Shams": 15,
    "Al-Layl": 21,
    "Ad-Dhuha": 11,
    "Ash-Sharh�(Al-Inshirah)": 8,
    "At-Tin": 8,
    "Al-Alaq": 19,
    "Al-Qadr": 5,
    "Al-Bayyinah": 8,
    "Az-Zalzalah": 8,
    "Al-'Aadiyat": 11,
    "Al-Qaari'ah": 11,
    "At-Takaathur": 8,
    "Al-'Asr": 3,
    "Al-Humazah": 9,
    "Al-Feel": 5,
    Quraish: 4,
    "Al-Maa'oon": 7,
    "Al-Kawthar": 6,
    "Al-Kaafiroon": 6,
    "An-Nasr": 2,
    "Al-Masad": 5,
    "Al-Ikhlas": 4,
    "Al-Falaq": 5,
    "An-Naas": 6,
  };
  const handleSurat = (e) => {
    const selectedSuratName = e.target.value;

    setsuratForm((prevSuratForm) => ({
      ...prevSuratForm,
      suratName: selectedSuratName,
      ayatNo: ayatsPerSurat[selectedSuratName] || prevSuratForm.ayatNo, // Set ayatNo based on the selected Surat
    }));
  };

  const handleNewSurat = (e) => {
    const selectedSuratNameObj = e.target.value;
    console.log(selectedSuratNameObj);
    const selectedAyats = displaySurats.filter(
      (adaatt) => adaatt.suratName === selectedSuratNameObj
    );
    console.log(selectedAyats[0]["ayatDetails"]);
    // setnewformDataNode((prevFormData) => {
    //   console.log(prevFormData);
    //   return {
    //     ...prevFormData,
    //     surat: [
    //       {
    //         suratName: selectedSuratNameObj,
    //       },
    //     ],
    //   };
    // });
    setcurrentAyat(selectedAyats[0]["ayatDetails"]);
  };

  const handleEdit = async () => {
    try {
      const dataForToday = await axios.patch(
        `https://api.myaadat.com/api/v1/aadatdata/${EditIdADM}`,

        {
          student: authState.id,
          remarkBoxes: formData.remarkBoxes,
          yesno: formData.yesno,
          customField: formData.customField,
          responsetypeCustomField: formData.responsetypeCustomField,
        },

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const existingSuratRecord = student.suratRecord;

      existingSuratRecord.push({
        suratName: suratForm.suratName,
        selectedAyatNo: suratForm.selectedAyatNo,
      });

      // Creating the requestBody object with the updated suratRecord
      const requestBody = {
        suratRecord: existingSuratRecord, // Converting Map back to an object
      };

      const response2 = await axios.patch(
        `https://api.myaadat.com/api/v1/users/${authState.id}`,

        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(dataForToday);

      throw alert("successfully edited!");
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      {/* <CButton color="primary" onClick={() => addToast(exampleToast)}>Send a toast</CButton> */}
      <CToaster className="p-3" placement="top-end" push={toast} ref={toaster} />
      {/* modal */}
      <CModal
        size="xl"
        backdrop="static"
        visible={EditForm}
        onClose={() => setEditForm(false)}
        aria-labelledby="StaticBackdropExampleLabel"
      >
        <CModalHeader>
          <CModalTitle id="StaticBackdropExampleLabel">Report</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <Report formsubmission={{ formSubmittedCount: student.formSubmittedCount, formNotSubmittedCount: student.formNotSubmittedCount }} suratPercentage={student.suratPercentage} />
        </CModalBody>
        <CModalFooter></CModalFooter>
      </CModal>
      <div
        className="card"
        style={{
          color: "black",
          width: "100%",
          maxWidth: "800px",
          margin: "0px auto",
          padding: "0px !important",
          marginBottom: "1.5rem",
          boxShadow: "0 .15rem 1.75rem 0 rgba(58,59,69,.15)",
        }}
      >
        <div
          className="card-header"
          style={{
            padding: "22px 50px",
            backgroundColor: "#135F77",
            color: "white",
            marginBottom: "0px",
            fontSize: "1rem",
            fontWeight: "400",
            lineHeight: "1.5",

            textAlign: "left",
          }}
        >
          <div
            className="innerhead"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <img className="user-image"  src={`https://api.myaadat.com/uploads/${student.photo}`}></img>
            {/* <img
              class="user-image"
              src={URL.createObjectURL(student.profilePic[0])}
              alt="Profile"
            /> */}
            {/* <a
              href="/profileview"
              class="btn-success edit-btn"
            >
              Edit<i class="fas fa-edit"></i>
            </a> */}

            <Link to="/profileview" className="btn-success edit-btn">Edit<i class="fas fa-edit"></i></Link>

            <h4 className="student-name">
              {student.firstName + " " + student.lastName}
            </h4>
            <div className="right-data">
              <h4 className="" style={{ fontSize: "1.5rem" }}>
                Class: <strong>{student.class}</strong>
              </h4>
            </div>
          </div>
        </div>
        <div className="card-body row margins">
          <div class="col-md-3 offset-md-9">
            <h3
              class="track"
              style={{ marginTop: "6%", fontWeight: "bold", fontSize: "14px" }}
            >
              Page Submission Expires in <br />
              <span id="demo1" class="tiktiktimer">
                {hoursLeft}h {minutesLeft}m {secondsLeft}
              </span>
            </h3>
            {/* <a
              class="vw only-dd"
              style={{ marginTop: "5%" }}
              onClick={() => setEditForm(true)}
            >
              View Report
            </a> */}
            <a class="vw only-dd"
              style={{ marginTop: "5%" }} onClick={()=> reportView()}>View Report</a>
          </div>
        </div>
        <span style={{ textAlign: "center" }} class="tracking">
          {student.currentDate} | {student.islamicCurrentDate}
        </span>
        <div class="text-ss web-ss">
          <h4>Day Completed in {student.islamicMonthName} : {student.daysPassedInIslamicMonth}</h4>
          <h4>
            Form Submitted : <span>{student.formNotSubmittedCount}</span>
          </h4>
          <h4>
            Form Not Submitted : <span>{student.formSubmittedCount}</span>
          </h4>
          <div>
            <h4>Percent completed (Surat):</h4>
            {/* <CProgress
              color="success"
              className="mb-4"
              value={(uniqueSurahCount / 604) * 100}
            >
              {`${((uniqueSurahCount / 604) * 100).toFixed(2)}%`}
            </CProgress> */}

            <CProgress
              color="success"
              className="mb-4"
              value={student.suratPercentage}
            >
              {`${student.suratPercentage}%`}
            </CProgress>
            {student.suratName!='' && student.ayatNumber!='' &&
            <>
            <strong>Surat Name: {student.suratName}</strong>
            <br/>
            <strong>Ayat Name: {student.ayatNumber}</strong>
            </>
            }
          </div>
        </div>
      </div>
      {/* <form> */}
      <CForm
        style={{ width: "100%", maxWidth: "800px" }}
        className="needs-validation"
        noValidate
        validated={validated}
        onSubmit={saveData}
      >
        
        
        

       

        {uniqueCategories &&
          uniqueCategories.map((category, categoryIndex) => (
            <div
              className="card shadow"
              style={{ width: "100%", maxWidth: "800px" }}
              key={categoryIndex}
            >
              <div
                className="card-header"
                style={{ fontSize: "25px", fontWeight: "400" }}
              >
                {category}
              </div>
              <div className="card-bodyy test">

              {/* {displaysubmittedAdaats.aadat.filter((subadaatt) => subadaatt.aadatId === '66694dee609d5482f5166e74').map((submittedadaat, index) => (
                
                <CCard>Hi {submittedadaat.aadatId}</CCard>
                ))} */}
                
                {displayAdaats.length > 0 &&
                  displayAdaats
                    .filter((adaatt) => adaatt.category.name === category)
                    .map((adaat, index) => (
                      

                      <><div>


                        {/* {displaysubmittedAdaats.aadat.filter((subadaatt) => subadaatt.aadatId === adaat._id).map((submittedadaat, index) => (

                        <CCard>{submittedadaat.aadatId}</CCard>
                        ))} */}

                      </div>
                      <CCard>
                          <CCardBody>
                            <div className="row">
                            <div className="lule col-md-5">{adaat.name}</div>

                            {/* surat handling */}

                           <div className="col-md-7 adaats-box">
                              {adaat.isSurat ? (
                                <div className="row">
                                  <div className="col-6">
                                    <CFormSelect
                                      id="validationCustom0789"
                                      label="Surat Name"
                                      name="suratName"
                                      onChange={handleSurat}
                                      value={suratForm.suratName && suratForm.suratName}
                                    >
                                      <option selected="" disabled="" value="">
                                        Choose...
                                      </option>

                                      <option value="Al-Fatihah">
                                        (1) Al-Fatihah
                                      </option>
                                      <option value="Al-Baqarah">
                                        (2) Al-Baqarah
                                      </option>
                                      <option value="Aali 'Imran">
                                        (3) Aali 'Imran
                                      </option>
                                      <option value="An-Nisa">(4) An-Nisa</option>
                                      <option value="Al-Ma'idah">
                                        (5) Al-Ma'idah
                                      </option>
                                      <option value="Al-An'am">
                                        (6) Al-An'am
                                      </option>
                                      <option value="Al-A'raf">
                                        (7) Al-A'raf
                                      </option>
                                      <option value="Al-Anfal">
                                        (8) Al-Anfal
                                      </option>
                                      <option value="At-Tawbah">
                                        (9) At-Tawbah
                                      </option>
                                      <option value="Yunus">(10) Yunus</option>
                                      <option value="Hud">(11) Hud</option>
                                      <option value="Yusuf">(12) Yusuf</option>
                                      <option value="Ar-Ra'd">
                                        (13) Ar-Ra'd
                                      </option>
                                      <option value="Ibrahim">
                                        (14) Ibrahim
                                      </option>
                                      <option value="Al-Hijr">
                                        (15) Al-Hijr
                                      </option>
                                      <option value="An-Nahl">
                                        (16) An-Nahl
                                      </option>
                                      <option value="Al-Isra">
                                        (17) Al-Isra
                                      </option>
                                      <option value="Al-Kahf">
                                        (18) Al-Kahf
                                      </option>
                                      <option value="Maryam">(19) Maryam</option>
                                      <option value="Ta-Ha">(20) Ta-Ha</option>
                                      <option value="Al-Anbiya">
                                        (21) Al-Anbiya
                                      </option>
                                      <option value="Al-Hajj">
                                        (22) Al-Hajj
                                      </option>
                                      <option value="Al-Mu'minun">
                                        (23) Al-Mu'minun
                                      </option>
                                      <option value="An-Nur">(24) An-Nur</option>
                                      <option value="Al-Furqan">
                                        (25) Al-Furqan
                                      </option>
                                      <option value="Ash-Shu'ara">
                                        (26) Ash-Shu'ara
                                      </option>
                                      <option value="An-Naml">
                                        (27) An-Naml
                                      </option>
                                      <option value="Al-Qasas">
                                        (28) Al-Qasas
                                      </option>
                                      <option value="Al-Ankabut">
                                        (29) Al-Ankabut
                                      </option>
                                      <option value="Ar-Rum">(30) Ar-Rum</option>
                                      <option value="Luqmaan">
                                        (31) Luqmaan
                                      </option>
                                      <option value="As-Sajdah">
                                        (32) As-Sajdah
                                      </option>
                                      <option value="Al-Ahzaab">
                                        (33) Al-Ahzaab
                                      </option>
                                      <option value="Saba (surah)">
                                        (34) Saba (surah)
                                      </option>
                                      <option value="Faatir">(35) Faatir</option>
                                      <option value="Ya-Sin">(36) Ya-Sin</option>
                                      <option value="As-Saaffaat">
                                        (37) As-Saaffaat
                                      </option>
                                      <option value="Saad">(38) Saad</option>
                                      <option value="Az-Zumar">
                                        (39) Az-Zumar
                                      </option>
                                      <option value="Ghafir">(40) Ghafir</option>
                                      <option value="Fussilat">
                                        (41) Fussilat
                                      </option>
                                      <option value="Ash-Shura">
                                        (42) Ash-Shura
                                      </option>
                                      <option value="Az-Zukhruf">
                                        (43) Az-Zukhruf
                                      </option>
                                      <option value="Ad-Dukhaan">
                                        (44) Ad-Dukhaan
                                      </option>
                                      <option value="Al-Jaathiyah">
                                        (45) Al-Jaathiyah
                                      </option>
                                      <option value="Al-Ahqaaf">
                                        (46) Al-Ahqaaf
                                      </option>
                                      <option value="Muhammad">
                                        (47) Muhammad
                                      </option>
                                      <option value="Al-Fath">
                                        (48) Al-Fath
                                      </option>
                                      <option value="Al-Hujuraat">
                                        (49) Al-Hujuraat
                                      </option>
                                      <option value="Qaaf">(50) Qaaf</option>
                                      <option value="Adh-Dhaariyaat">
                                        (51) Adh-Dhaariyaat
                                      </option>
                                      <option value="At-Toor">
                                        (52) At-Toor
                                      </option>
                                      <option value="An-Najm">
                                        (53) An-Najm
                                      </option>
                                      <option value="Al-Qamar">
                                        (53) Al-Qamar
                                      </option>
                                      <option value="Ar-Rahman">
                                        (55) Ar-Rahman
                                      </option>
                                      <option value="Al-Waqi'ah">
                                        (56) Al-Waqi'ah
                                      </option>
                                      <option value="Al-Hadeed">
                                        (57) Al-Hadeed
                                      </option>
                                      <option value="Al-Mujadila">
                                        (58) Al-Mujadila
                                      </option>
                                      <option value="Al-Hashr">
                                        (59) Al-Hashr
                                      </option>
                                      <option value="Al-Mumtahanah">
                                        (60) Al-Mumtahanah
                                      </option>
                                      <option value="As-Saff">
                                        (61) As-Saff
                                      </option>
                                      <option value="Al-Jumu'ah">
                                        (62) Al-Jumu'ah
                                      </option>
                                      <option value="Al-Munafiqoon">
                                        (63) Al-Munafiqoon
                                      </option>
                                      <option value="At-Taghabun">
                                        (64) At-Taghabun
                                      </option>
                                      <option value="At-Talaq">
                                        (65) At-Talaq
                                      </option>
                                      <option value="At-Tahreem">
                                        (66) At-Tahreem
                                      </option>
                                      <option value="Al-Mulk">
                                        (67) Al-Mulk
                                      </option>
                                      <option value="Al-Qalam">
                                        (68) Al-Qalam
                                      </option>
                                      <option value="Al-Haaqqa">
                                        (69) Al-Haaqqa
                                      </option>
                                      <option value="Al-Ma'aarij">
                                        (70) Al-Ma'aarij
                                      </option>
                                      <option value="Nuh">(71) Nuh</option>
                                      <option value="Al-Jinn">
                                        (72) Al-Jinn
                                      </option>
                                      <option value="Al-Muzzammil">
                                        (73) Al-Muzzammil
                                      </option>
                                      <option value="Al-Muddaththir">
                                        (74) Al-Muddaththir
                                      </option>
                                      <option value="Al-Qiyamah">
                                        (75) Al-Qiyamah
                                      </option>
                                      <option value="Al-Insaan">
                                        (76) Al-Insaan
                                      </option>
                                      <option value="Al-Mursalaat">
                                        (77) Al-Mursalaat
                                      </option>
                                      <option value="An-Naba'">
                                        (78) An-Naba'
                                      </option>
                                      <option value="An-Naazi'aat">
                                        (79) An-Naazi'aat
                                      </option>
                                      <option value="Abasa">(80) Abasa</option>
                                      <option value="At-Takweer">
                                        (81) At-Takweer
                                      </option>
                                      <option value="Al-Infitar">
                                        (82) Al-Infitar
                                      </option>
                                      <option value="Al-Mutaffifeen">
                                        (83) Al-Mutaffifeen
                                      </option>
                                      <option value="Al-Inshiqaaq">
                                        (84) Al-Inshiqaaq
                                      </option>
                                      <option value="Al-Burooj">
                                        (85) Al-Burooj
                                      </option>
                                      <option value="At-Taariq">
                                        (86) At-Taariq
                                      </option>
                                      <option value="Al-A'la">
                                        (87) Al-A'la
                                      </option>
                                      <option value="Al-Ghaashiyah">
                                        (88) Al-Ghaashiyah
                                      </option>
                                      <option value="Al-Fajr">
                                        (89) Al-Fajr
                                      </option>
                                      <option value="Al-Balad">
                                        (90) Al-Balad
                                      </option>
                                      <option value="Ash-Shams">
                                        (91) Ash-Shams
                                      </option>
                                      <option value="Al-Layl">
                                        (92) Al-Layl
                                      </option>
                                      <option value="Ad-Dhuha">
                                        (93) Ad-Dhuha
                                      </option>
                                      <option value="Ash-Sharh�(Al-Inshirah)">
                                        (94) Ash-Sharh�(Al-Inshirah)
                                      </option>
                                      <option value="At-Tin">(95) At-Tin</option>
                                      <option value="Al-Alaq">
                                        (96) Al-Alaq
                                      </option>
                                      <option value="Al-Qadr">
                                        (97) Al-Qadr
                                      </option>
                                      <option value="Al-Bayyinah">
                                        (98) Al-Bayyinah
                                      </option>
                                      <option value="Az-Zalzalah">
                                        (99) Az-Zalzalah
                                      </option>
                                      <option value="Al-'Aadiyat">
                                        (100) Al-'Aadiyat
                                      </option>
                                      <option value="Al-Qaari'ah">
                                        (101) Al-Qaari'ah
                                      </option>
                                      <option value="At-Takaathur">
                                        (102) At-Takaathur
                                      </option>
                                      <option value="Al-'Asr">
                                        (103) Al-'Asr
                                      </option>
                                      <option value="Al-Humazah">
                                        (104) Al-Humazah
                                      </option>
                                      <option value="Al-Feel">
                                        (105) Al-Feel
                                      </option>
                                      <option value="Quraish">
                                        (106) Quraish
                                      </option>
                                      <option value="Al-Maa'oon">
                                        (107) Al-Maa'oon
                                      </option>
                                      <option value="Al-Kawthar">
                                        (108) Al-Kawthar
                                      </option>
                                      <option value="Al-Kaafiroon">
                                        (109) Al-Kaafiroon
                                      </option>
                                      <option value="An-Nasr">
                                        (110) An-Nasr
                                      </option>
                                      <option value="Al-Masad">
                                        (111) Al-Masad
                                      </option>
                                      <option value="Al-Ikhlas">
                                        (112) Al-Ikhlas
                                      </option>
                                      <option value="Al-Falaq">
                                        (113) Al-Falaq
                                      </option>
                                      <option value="An-Naas">
                                        (114) An-Naas
                                      </option>
                                    </CFormSelect>
                                  </div>
                                  <div className="col-6">
                                    <CFormSelect
                                      id="validationCustom0789"
                                      label="To Ayat"
                                      name="ayatName"
                                      onChange={(e) => {
                                        setsuratForm({
                                          ...suratForm,
                                          selectedAyatNo: e.target.value,
                                        });
                                      } }
                                      value={suratForm.selectedAyatNo}
                                    >
                                      <option selected="" disabled="" value="">
                                        Choose...
                                      </option>
                                      {Array.from(
                                        { length: suratForm.ayatNo },
                                        (_, index) => (
                                          <option
                                            key={index + 1}
                                            value={index + 1}
                                          >
                                            {index + 1}
                                          </option>
                                        )
                                      )}
                                    </CFormSelect>
                                  </div>
                                </div>
                              ) : null}
                           
                            
                            {adaat.responseType.map((type, typeIndex) => (
                              <div key={typeIndex}>
                                {(() => {
                                  switch (type) {
                                    case "yesno":
                                      return (
                                        <>
                                          {(adaat.responsevalues && adaat.responsevalues.yesno) && adaat.responsevalues.yesno=='true' ? (
                                            <>
                                            <input
                                              type="radio"
                                              className="btn-check"
                                              key={`${categoryIndex}_${index}_${typeIndex}_yes`}
                                              id={`flexCheckDefaultYes_${categoryIndex}_${index}_${typeIndex}`}
                                              required={adaat.isCompulsory}
                                              value="yes"
                                              name={`yesno_${categoryIndex}_${index}_${typeIndex}`}
                                              onChange={(e) => handlechange(e, adaat)} defaultChecked/>
                                            <label
                                              className="btn btn-outline-success btn-sm round-1"
                                              for={`flexCheckDefaultYes_${categoryIndex}_${index}_${typeIndex}`}
                                            >
                                              yes
                                            </label>
                                            <input
                                              type="radio"
                                              className="btn-check"
                                              key={`${categoryIndex}_${index}_${typeIndex}_no`}
                                              id={`flexCheckDefaultNo_${categoryIndex}_${index}_${typeIndex}`}
                                              name={`yesno_${categoryIndex}_${index}_${typeIndex}`}
                                              required={adaat.isCompulsory}
                                              value="no"
                                              onChange={(e) => handlechange(e, adaat)} />
                                            <label
                                              className="btn btn-outline-danger btn-sm round-1"
                                              for={`flexCheckDefaultNo_${categoryIndex}_${index}_${typeIndex}`}
                                            >
                                              no
                                            </label>
                                            <div className="invalid-feedback">
                                              Please select yes/no.
                                            </div>{" "}
                                            </>
                                            ):(
                                            <>
                                          </>
                                          )}

{(adaat.responsevalues && adaat.responsevalues.yesno) && adaat.responsevalues.yesno=='false' ? (
                                            <>
                                            <input
                                              type="radio"
                                              className="btn-check"
                                              key={`${categoryIndex}_${index}_${typeIndex}_yes`}
                                              id={`flexCheckDefaultYes_${categoryIndex}_${index}_${typeIndex}`}
                                              required={adaat.isCompulsory}
                                              value="yes"
                                              name={`yesno_${categoryIndex}_${index}_${typeIndex}`}
                                              onChange={(e) => handlechange(e, adaat)} defaultChecked/>
                                            <label
                                              className="btn btn-outline-success btn-sm round-1"
                                              for={`flexCheckDefaultYes_${categoryIndex}_${index}_${typeIndex}`}
                                            >
                                              yes
                                            </label>
                                            <input
                                              type="radio"
                                              className="btn-check"
                                              key={`${categoryIndex}_${index}_${typeIndex}_no`}
                                              id={`flexCheckDefaultNo_${categoryIndex}_${index}_${typeIndex}`}
                                              name={`yesno_${categoryIndex}_${index}_${typeIndex}`}
                                              required={adaat.isCompulsory}
                                              value="no"
                                              onChange={(e) => handlechange(e, adaat)} defaultChecked/>
                                            <label
                                              className="btn btn-outline-danger btn-sm round-1"
                                              for={`flexCheckDefaultNo_${categoryIndex}_${index}_${typeIndex}`}
                                            >
                                              no
                                            </label>
                                            <div className="invalid-feedback">
                                              Please select yes/no.
                                            </div>{" "}
                                            </>
                                            ):(
                                            <>
                                          </>
                                          )}

{!(adaat.responsevalues && adaat.responsevalues.yesno) ? (
                                            <>
                                            <input
                                              type="radio"
                                              className="btn-check"
                                              key={`${categoryIndex}_${index}_${typeIndex}_yes`}
                                              id={`flexCheckDefaultYes_${categoryIndex}_${index}_${typeIndex}`}
                                              required={adaat.isCompulsory}
                                              value="yes"
                                              name={`yesno_${categoryIndex}_${index}_${typeIndex}`}
                                              onChange={(e) => handlechange(e, adaat)} defaultChecked/>
                                            <label
                                              className="btn btn-outline-success btn-sm round-1"
                                              for={`flexCheckDefaultYes_${categoryIndex}_${index}_${typeIndex}`}
                                            >
                                              yes
                                            </label>
                                            <input
                                              type="radio"
                                              className="btn-check"
                                              key={`${categoryIndex}_${index}_${typeIndex}_no`}
                                              id={`flexCheckDefaultNo_${categoryIndex}_${index}_${typeIndex}`}
                                              name={`yesno_${categoryIndex}_${index}_${typeIndex}`}
                                              required={adaat.isCompulsory}
                                              value="no"
                                              onChange={(e) => handlechange(e, adaat)}/>
                                            <label
                                              className="btn btn-outline-danger btn-sm round-1"
                                              for={`flexCheckDefaultNo_${categoryIndex}_${index}_${typeIndex}`}
                                            >
                                              no
                                            </label>
                                            <div className="invalid-feedback">
                                              Please select yes/no.
                                            </div>{" "}
                                            </>
                                            ):(
                                            <>
                                          </>
                                          )}

                                        </>
                                      );

                                    case "custom":
                                      return (
                                        <>
                                        <div className="text-end customType">
                                          {/*  content for responseType 'customfield' */}
                                        
                                          {adaat.customType && adaat.customType["values"].map(
                                            (option, optionIndex) => (
                                                        
                                              <div className={optionIndex === 0 ? 'form-check form-check-inline pl-0 mr-0' : 'form-check form-check-inline pl-0 mr-0'}>
                                                
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  key={`checkbox_${categoryIndex}_${index}_${typeIndex}`}
                                                  id={`checkbox_${categoryIndex}_${index}_${optionIndex}`}
                                                  name={`checkbox_${categoryIndex}_${index}_${typeIndex}`}
                                                  value={option}
                                                  required={adaat.isCompulsory}
                                                  onChange={(e) => updateCustomTypeCheckbox(
                                                    // `${fieldIndex}_${typeIndex}`,
                                                    `${typeIndex}_customField`,
                                                    "customField",
                                                    "checkbox",
                                                    e.target,
                                                    adaat
                                                  )} 
                                                  {...(adaat.responsevalues && adaat.responsevalues.custom==option && {checked:true})}
                                                  />
                                                  
                                                <label for={`checkbox_${categoryIndex}_${index}_${optionIndex}`} className="btn btn-outline-success btn-sm round-1">{option}</label>
                                                
                                                {adaat.customType["values"].length == (optionIndex+1) &&
                                                
                                                <div className="invalid-feedback">
                                                  Please select
                                                </div>}
                                          </div>
                                            )
                                          )}
                                        </div>
                                      </>
                                      );
                                    default:
                                      return null;
                                  }
                                })()}
                              </div>
                            ))}

                            {/* custom field outside of response type */}
                            {adaat.customField.map((field, fieldIndex) => (
                              <>
{field.fieldTitle!='' && (
                              <div key={fieldIndex} className="col-md-6">
                                {/* <div>
{displaysubmittedAdaats.aadat.filter((subadaatt) => subadaatt.aadatId === adaat._id).map((submittedadaat, index) => (

  <CCard>{submittedadaat.aadatId}</CCard>
  ))}
  </div> */}
                                <p>{field.fieldTitle}
                                </p>
                                {field.fieldType === "dropdown" && (
                                  <CFormSelect
                                    onChange={(e) => updateCustomFieldDropdown(
                                      // `${fieldIndex}_${typeIndex}`,
                                      `${fieldIndex}_${field.fieldTitle}`,
                                      field.fieldTitle,
                                      "dropdown",
                                      e.target.value,
                                      adaat
                                    )}
                                    defaultValue={field.defaultvalue}
                                  >
                                    <option value="" disabled>
                                      {/* {formData.customField.find(
(cf) => cf.adaatId === adaat._id
)?.options[0] || "Select an option"} */}
                                      Select an option
                                    </option>

                                    {field.options.map((option, optionIndex) => (
                                      <option key={optionIndex} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </CFormSelect>
                                )}
                                {field.fieldType === "checkbox" && (
                                  <div style={{paddingLeft : "20px"}}>
                                    {field.options.map((option, optionIndex) => (
                                      <>
                                      
                                      <span key={optionIndex} style={{ display: "block" }}>
                                        
                                        {/* <CFormCheck
                          id={`checkbox_${fieldIndex}_${optionIndex}`}
                          name={`checkbox_${fieldIndex}`}
                          value={option}
                        /> */}

                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          key={`checkbox_${fieldIndex}_${optionIndex}`}
                                          id={`checkbox_${fieldIndex}_${optionIndex}`}
                                          name={`checkbox_${fieldIndex}`}
                                          value={option}
                                          onChange={(e) => updateCustomFieldCheckbox(
                                            // `${fieldIndex}_${typeIndex}`,
                                            `${fieldIndex}_${field.fieldTitle}`,
                                            field.fieldTitle,
                                            "checkbox",
                                            e.target,
                                            adaat
                                          )} />
                                          <label for={`checkbox_${fieldIndex}_${optionIndex}`} class="form-check-label">{option}</label>
                                        
                                      </span>
                                      </>
                                    ))}
                                  </div>
                                )}
                              </div>
                              )}
                              </>
                            ))}
                          </div>
                          <div>
                          {adaat.responseType.map((type, typeIndex) => (
                              <div key={typeIndex}>
                                {type =='surat'?
                                <div className="row">
                                <div className="col-6">
                                  <CFormSelect
                                    id="validationCustom0789"
                                    label="Surat Name"
                                    name="suratName"
                                    onChange={handleNewSurat}
                                    defaultValue={adaat.responsevalues && adaat.responsevalues.suratName ? adaat.responsevalues.suratName :'Choose...'}
                                  >
                                    <option selected="" disabled="" value="">
                                      Choose...
                                    </option>
              
                                    {displaySurats.length > 0 &&
                                      displaySurats.map((suratItem, suratIndex) => (
                                        <>
                                          <option
                                            key={suratIndex}
                                            value={suratItem.suratName}
                                          >
                                           ({suratItem.orderName}) {suratItem.suratName}
                                          </option>
                                        </>
                                      ))}
                                  </CFormSelect>
                                </div>
              
                                <div className="col-6">
                                  <CFormSelect
                                    id="validationCustom0789"
                                    label="To Ayat"
                                    name="ayatName"
                                    onChange={(e) => updateSurat(e.target.value,
                                      adaat)}
                                      defaultValue={adaat.responsevalues && adaat.responsevalues.suratid ? adaat.responsevalues.suratid:'Choose...'}
                                  >
                                    <option disabled="" value="">
                                      Choose...
                                    </option>
              
                                    {currentAyat.length > 0 &&
                                      currentAyat.map((ayatItem, ayatIndex) => (
                                        <>
                                          <option key={ayatIndex} value={ayatItem._id}>
                                            {ayatItem.ayatNo}
                                          </option>
                                        </>
                                      ))}
                                  </CFormSelect>
                                </div>
                              </div>
                                :''}
                                {(() => {
                                  switch (type) {
                                   case "remarkbox":
                                      return (
                                        <div>
                                          {/*  content for responseType 'remarkbox' */}

                                          <div className="form-floating mt-2">
                                            <CFormTextarea
                                              class="form-control"
                                              placeholder="Leave a comment here"
                                              // id="floatingTextarea"
                                              // name={`remarkbox${index}`}
                                              // value={
                                              //   formData[`remarkbox${index}`]
                                              // }
                                              // onChange={(e) => {
                                              //   handlechange(e, adaat);
                                              // }}
                                              id={`remarkbox_${categoryIndex}_${index}_${typeIndex}`}
                                              name={`remarkbox_${categoryIndex}_${index}_${typeIndex}`}
                                              // value={
                                              //   // formData.remarkBoxes[
                                              //   //   `remarkbox_${categoryIndex}_${index}_${typeIndex}`
                                              //   // ]
                                              //   formData.remarkBoxes &&
                                              //     formData.remarkBoxes[`remarkbox_${categoryIndex}_${index}_${typeIndex}`] &&
                                              //     formData.remarkBoxes[`remarkbox_${categoryIndex}_${index}_${typeIndex}`].value
                                              //     ? formData.remarkBoxes[`remarkbox_${categoryIndex}_${index}_${typeIndex}`].value
                                              //     : null}
                                              defaultValue={(adaat.responsevalues && adaat.responsevalues.remarkbox)?adaat.responsevalues.remarkbox:''}
                                              onChange={(e) => handlechange(e, adaat)}
                                              required={adaat.isCompulsory}
                                            ></CFormTextarea>
                                            <label for="floatingTextarea">
                                              Enter your remarks
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    case "image":
                                      return (
                                        <div className="mt-2">
                                          {/*  content for responseType 'images' */}
                                          <div key={index}>
                                            <CFormInput
                                              type="file"
                                              className="form-control"
                                              label="Images"
                                              name={`image${index}`}
                                              id={`inputGroupFile${index + 1}`}
                                              onChange={(e) => handleImagechange(e, adaat)}
                                              // required={adaat.isCompulsory}
                                              {...(!(adaat.responsevalues && adaat.responsevalues.images) ? {required:adaat.isCompulsory}:'')}
                                              multiple // Allow multiple file selection
                                            />
                                            {(adaat.responsevalues && adaat.responsevalues.images) && (
                                            <div className="row" style={{paddingTop:"10px"}}>
                                              
                                              {(typeof adaat.responsevalues.images == 'object') && (
                                                  adaat.responsevalues.images.map((image, key) => (
                                                    <div className="col">
                                                  <img key={key} className="img-thumbnail" style={{maxWidth:"200px"}} src={`https://api.myaadat.com/${image}`}/>
                                                  </div>
                                                  ))
                                              )}

{(typeof adaat.responsevalues.images == 'string') && (
                                                  
                                                    <div className="col">
                                                  <img className="img-thumbnail" style={{maxWidth:"200px"}} src={`https://api.myaadat.com/${adaat.responsevalues.images}`}/>
                                                  </div>
                                              )}
                                            
                                            
                                            </div>
                                )} 
                                             </div>
                                        </div>
                                      );
                                    default:
                                      return null;
                                  }
                                })()}
                              </div>
                            ))}
                          </div>
                          </div>
                          </CCardBody>
                        </CCard></>
                    ))}
              </div>
            </div>
          ))}


{displayMiqaats.length > 0 &&
          
          <>
<div
              
              className="card shadow"
              style={{ width: "100%", maxWidth: "800px" }}
            >
              <div
                className="card-header"
                style={{ fontSize: "25px", fontWeight: "400" }}
              >
                Miqaat
              </div>
            
              {displayMiqaats.map((miqaatItem, miqaatIndex) => (
              <div key={miqaatIndex} className="card-bodyy test">
                <CCard>
                  <CCardBody>
                    <h3>{miqaatItem.name}</h3>
                    
                    {miqaatItem.description}

                  <div>
                        <div>
                          <input
                            type="radio"
                            className="btn-check"
                            key={`${miqaatItem.name}_${miqaatIndex}_yes`}
                            id={`flexCheckDefaultYes_${miqaatItem.name}_${miqaatIndex}`}
                            required=""
                            value="yes"
                            name={`yesno_${miqaatItem.name}_${miqaatIndex}`}
                            onChange={(e) =>
                              handlemiqaatchange(e, miqaatItem)
                            }
                          />
                          <label
                            className="btn btn-outline-success btn-sm round-1"
                            for={`flexCheckDefaultYes_${miqaatItem.name}_${miqaatIndex}`}
                          >
                            yes
                          </label>
                          <input
                            type="radio"
                            className="btn-check"
                            key={`${miqaatItem.name}_${miqaatIndex}_no`}
                            id={`flexCheckDefaultNo_${miqaatItem.name}_${miqaatIndex}`}
                            name={`yesno_${miqaatItem.name}_${miqaatIndex}`}
                            required=""
                            value="no"
                            onChange={(e) =>
                              handlemiqaatchange(e, miqaatItem)
                            }
                          />
                          <label
                            className="btn btn-outline-danger btn-sm round-1"
                            for={`flexCheckDefaultNo_${miqaatItem.name}_${miqaatIndex}`}
                          >
                            no
                          </label>
                          <div className="invalid-feedback">
                            Please select yes/no.
                          </div>{" "}
                        </div>

                        
                      </div>

                  </CCardBody>
                </CCard>
              </div>
              ))}

              
        </div>
            
          </>
        }


        <div className="d-flex justify-content-center align-items-center">
          {isLoding ? <CSpinner /> : null}
        </div>
        {ReadyToEdit ? null : (
          <div className="text-center">
            <CButton className="btn btn-dark" type="submit">
              Save
            </CButton>
          </div>
        )}
      </CForm>
      {ReadyToEdit ? (
        <CButton className="btn btn-dark" type="button" onClick={handleEdit}>
          Confirm Edit
        </CButton>
      ) : null}
    </div>
  );
}

export default StudentView;
